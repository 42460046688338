<template>
  <v-container class="pa-1" fluid>
    <v-form>
      <v-row dense>
        <v-col class="pa-3" cols="4">
          <v-row>
            <v-col cols="12" class="pa-3">
              <v-img
                class="rounded-pill"
                v-if="SubmitActive"
                :lazy-src="record.profile_photo"
                max-height="150"
                max-width="150"
                :src="record.profile_photo"
              ></v-img>

              <template v-else>
                <v-sheet color="grey lighten-4">
                  <v-skeleton-loader
                    type="image"
                    max-height="100"
                    max-width="100"
                  ></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-file-input
                v-model="file"
                @change="uploadImage"
                truncate-length="15"
                label="Profile Photo"
                filled
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-col> </v-row
        ></v-col>
        <v-col class="pa-3" cols="8">
          <v-row>
            <v-row dense>
              <v-col cols="4" class="pa-2">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.status"
                  label="Active"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" class="pa-2">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.SUPERUSER"
                  label="Superuser"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" class="pa-2">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.e_pass"
                  label="Email Password"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" class="pa-2">
                <v-text-field
                  outlined
                  dense
                  :filled="!docCreate"
                  :readonly="!docCreate"
                  v-model="record.account"
                  label="User Code"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="pa-2">
                <v-text-field
                  outlined
                  dense
                  v-model="record.name"
                  label="Full Name"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="pa-2">
                <v-text-field
                  outlined
                  dense
                  :name="`username_${Math.random()}`"
                  v-model="record.email"
                  label="Email"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="pa-2">
                <v-text-field
                  outlined
                  dense
                  autocomplete="nope"
                  v-model="password"
                  label="Password"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pa-2">
                <v-text-field
                  outlined
                  dense
                  v-model="record.phone_number"
                  type="phone"
                  label="Phone Number"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="pa-2">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.DfltsGroup"
                  :items="userDefaults"
                  item-text="Name"
                  item-value="id"
                  label="User Default"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pa-2">
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.Department"
                  :items="departments"
                  item-text="Name"
                  item-value="id"
                  label="Department"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4" class="pa-2">
                <v-text-field
                  outlined
                  dense
                  v-model="record.ExtRef"
                  type="phone"
                  label="External Refrence"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="pa-2">
                <!-- <v-text-field
          outlined
          dense
          :filled="!docCreate"
          :readonly="!docCreate"
          v-model="record.EmpID"
          type="phone"
          label="Employee ID"
        ></v-text-field> -->
                <v-autocomplete
                  outlined
                  dense
                  v-model="record.EmpID"
                  :items="employees"
                  item-value="empID"
                  item-text="full_name"
                  label="Employee ID"
                ></v-autocomplete>
              </v-col>

              <!-- User Type -->
              <v-col cols="4" class="pa-2">
                <v-autocomplete
                  dense
                  outlined
                  v-model="record.type"
                  :items="userTypes"
                  label="User Type"
                  item-text="name"
                  item-value="value"
                ></v-autocomplete>
              </v-col>
              <v-col v-if="record.type === 'E'" cols="4" class="pa-2">
                <v-autocomplete
                  dense
                  outlined
                  v-model="record.CardCode"
                  :items="BusinessPartners"
                  label="Business Partners"
                  :item-text="(item) => item.CardCode + '  -  ' + item.CardName"
                  item-value="CardCode"
                ></v-autocomplete>
              </v-col>
              <!-- end -->
              <v-col cols="4" class="pa-2">
                <v-file-input
                  single
                  v-model="signatureAttachment"
                  small-chips
                  show-size
                  label="Signature Attachment"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="pa-2">
          <v-autocomplete
            v-model="record.branches"
            :items="branches"
            attach
            chips
            small
            dense
            outlined
            clearable
            @input="searchInputB = null"
            :search-input.sync="searchInputB"
            deletable-chips
            label="Branch Assignments"
            item-text="BPLName"
            item-value="id"
            multiple
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" class="pa-2">
          <v-autocomplete
            v-model="record.usergroups"
            :items="userGroups"
            attach
            chips
            dense
            outlined
            small
            clearable
            @input="searchInput = null"
            :search-input.sync="searchInput"
            deletable-chips
            label="Groups"
            item-text="name"
            item-value="id"
            multiple
          ></v-autocomplete>
        </v-col>

        <v-row dense>
          <v-col cols="2" class="pa-2">
            <v-checkbox
              outlined
              dense
              v-model="useLocalSearch"
              label="Search Data Locally"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" class="pa-2">
            <v-text-field
              label="URL"
              v-if="useLocalSearch"
              v-model="record.localUrl"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pa-5">
            <div class="d-flex justify-end">
              <v-btn
                color="accent"
                :disabled="!this.SubmitActive"
                @click="sendData"
                :loading="$store.state.loader"
              >
                <v-icon left>mdi-content-save</v-icon>Save
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    itemsData: {
      type: Array,
    },
    initial: {
      type: Object,
    },
    docCreate: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("salesEmployee", ["salesEmployees"]),
    ...mapGetters("uom", ["uoms"]),
    ...mapGetters(["user"]),
  },
  data() {
    return {
      BusinessPartners: [],
      record: null,
      e_pass: false,
      loader: false,
      signatureAttachment: null,
      search: "",
      userGroups: [],
      singleSelect: true,
      searchInput: null,
      searchInputB: null,
      selectionType: "leaf",
      employee: {},
      trueValue: true,
      userDefaults: [],
      departments: [],
      employees: [],
      branches: [],
      DfltsGroup: {},
      SUPERUSER: true,
      password: null,
      dialog: false,
      file: null,
      SubmitActive: true,
      useLocalSearch: false,
      status: [
        { name: "Pending", value: "P" },
        { name: "Confirmed", value: "C" },
      ],
      roles: [
        { name: "System Manager", value: "Sys" },
        { name: "Managers", value: "Manager" },
        { name: "Administrator", value: "Admin" },
      ],
      headers: [
        { text: "Employee", value: "employee.SlpName" },
        { text: "Warehouse", value: "warehouse.WhsName" },
      ],
      userTypes: [
        { name: "Approver", value: "A" },
        { name: "API User", value: "AU" },
        { name: "Normal User", value: "NU" },
        { name: "External User", value: "E" },
      ],
    };
  },
  watch: {
    initial: {
      handler: "setInitial",
      immediate: true,
    },
    signatureAttachment: {
      handler: "setUploadSingature",
      immediate: true,
    },
  },
  methods: {
    setInitial(val) {
      this.useLocalSearch = val.useLocalSearch;
      this.record = { ...val };
    },
    clicked($event) {
      const userDefault = $event.item;
      this.DfltsGroup = userDefault;
      this.dialog = false;
    },
    getUserDefaults() {
      const self = this;
      this.$store
        .dispatch("get", `/user_defaults`)
        .then((res) => {
          self.userDefaults = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    selectItem(data) {
      this.record = data;
      this.dialog = false;
    },
    setStartDate(date) {
      this.record.StartDate = date;
    },
    setEndDate(date) {
      this.record.EndDate = date;
    },
    sendData() {
      this.record.licence = "2233";
      this.record.password = this.password ? this.password : null;
      this.$store.commit("loader", true);
      this.record.signature = this.signatureAttachment;
      this.record.useLocalSearch = this.useLocalSearch;
      this.$emit("data", this.record);
    },
    userDefaultsModal() {
      this.dialog = true;
    },
    getGroups() {
      const url = "/usergroup";
      const self = this;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.userGroups = res.ResponseData;
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getBranches() {
      const self = this;
      this.$store
        .dispatch("get", `/branches?addingUser=1`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.branches = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDepartments() {
      const self = this;
      this.$store
        .dispatch("get", `/departments`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.departments = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    setUploadSingature(val) {
      if (val) {
        const data = new FormData();
        data.append("signatureData", val);
        data.append("username", this.record.account);
        const url = "/user-signature";
        const self = this;
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.signatureAttachment = null;
            } else {
              self.$refs.snackbar.show(res.ResultDesc, "red");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // end
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee-master-data`)
        .then((res) => {
          self.employees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    uploadImage() {
      const file = this.file;
      const data = new FormData();
      data.append("file", file);
      data.append("UserSign", this.$route.params.id);
      const url = "/user-profile-photo";
      const self = this;
      this.SubmitActive = false;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          this.SubmitActive = true;
          if (res.ResultCode == 1200) {
            self.record.profile_photo = res.ResponseData;
            this.getGroups();
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBpartners() {
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          let Partners = {
            CardCode: "-1",
            CardName: "ALL",
          };

          this.BusinessPartners = res.ResponseData;
          this.BusinessPartners.push(Partners);
          this.BusinessPartners.sort((a, b) => a.CardCode - b.CardCode);
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getUserDefaults();
    this.getBranches();
    this.getGroups();
    this.getDepartments();
    this.getEmployees();
    this.getBpartners();
  },
};
</script>

<style lang="scss" scoped></style>
