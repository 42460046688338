import { render, staticRenderFns } from "./editor.vue?vue&type=template&id=5c31a75f&scoped=true"
import script from "./editor.vue?vue&type=script&lang=js"
export * from "./editor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c31a75f",
  null
  
)

export default component.exports